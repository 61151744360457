import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import Modal from "react-modal"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import moment from 'moment';
import 'moment/locale/fr';

export const FRmonthYearFormat = (date) => {
  return moment(date).locale('fr').format('MMMM YYYY');
};

export const monthYearFormat = (date) => {
  const d = new Date(date);
  const month = d.toLocaleString('default', { month: 'long' }); // This will get the full month name
  const year = d.getFullYear();
  return `${month} ${year}`;
};

const InsightsReports = props => {
  const { allContentfulInsightsReports } = props.data

  const [idx, setIdx] = useState(null)
  const [modalIsOpen, setIsOpen] = useState(false)
  function openModal(i) {
    setIdx(i)
    setIsOpen(true)
  }
  function closeModal() {
    setIsOpen(false)
  }


  Modal.setAppElement("#___gatsby")
  return (
    <div className="caseStudiesPage insightsPage">
      <Layout>
        <Seo title="Insights Reports" />
        <div className="main_content_wrapper casestudies_blog">
          <div className="obie_awards_blog_section casestudies_blog">
            <div className="container">
              <div className="obie_awards_blog_inner_section">
                <div className="creative_library_dropdown">
                  <div className="common_section_title">
                    <h2 className="en-only">
                      Insights <span> Reports</span>
                    </h2>
                    <h2 className="fr-only">
                    Rapport <span> D’analyse</span>
                    </h2>
                  </div>
                </div>

                <div className="obie_awards_blog_row">
                  {allContentfulInsightsReports.nodes.map((cs, i) => (
                    <div className="obie_awards_blog_col" key={cs.id}>
                      <div className="obie_awards_blog_info">
                        <a
                          href={cs.subtitle}
                          className="oa_blog_img"
                          target="_blank"
                        >
                          <GatsbyImage
                            image={getImage(cs.image)}
                            alt="casestudies blog"
                            className="slider_images"
                          />
                          <a
                            href={cs.subtitle}
                            className="awards_btn platinum_color"
                            target="_blank"
                          >
                            {cs.year}
                          </a>
                        </a>
                        <div className="oa_blog_details">
                          <span className="oa_blog_sub_text en-only">
                            {monthYearFormat(cs.reportDate)}  
                          </span>
                          <span className="oa_blog_sub_text fr-only">
                            {FRmonthYearFormat(cs.reportDate)}  
                          </span>
                          <a href={cs.subtitle} target="_blank">
                            <h3 className="oa_blog_title">{cs.title}</h3>
                          </a>
                          <a
                            href={cs.subtitle}
                            className="see_details_btn slider_images en-only"
                            target="_blank"
                          >
                            View Report
                            <i
                              className="fa fa-caret-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                           <a
                            href={cs.subtitle}
                            className="see_details_btn slider_images fr-only"
                            target="_blank"
                          >
                            Voir le rapport
                            <i
                              className="fa fa-caret-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div>
                      </div>

                      {modalIsOpen && (
                        <Modal
                          isOpen={idx === i}
                          onRequestClose={closeModal}
                          contentLabel={i.toString()}
                          id={i.toString()}
                          key={i}
                          testId={i.toString()}
                          bodyOpenClassName={`caseStudiesModal modalOpen`}
                        >
                          <div className="modal-content">
                            <div className="modal_close_btn">
                              <span onClick={closeModal}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17.688"
                                  height="17.688"
                                  viewBox="0 0 17.688 17.688"
                                >
                                  <path
                                    id="close-icon"
                                    d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                    transform="translate(-7.5 -7.5)"
                                    fill="#fff"
                                  ></path>
                                </svg>
                              </span>
                            </div>
                            <div className="modal_body">
                              <div className="casestudy-modal-content">
                                <div className="country_details_wrap">
                                  <div className="country_details">
                                    <h1>{cs.title}</h1>
                                    <p>{cs.subtitle}</p>
                                  </div>
                                  <div className="country_years">
                                    <span>{cs.year}</span>
                                  </div>
                                </div>
                                <div className="modal_inner_img_wrap">
                                  <GatsbyImage
                                    image={getImage(cs.image)}
                                    alt="modal"
                                  />
                                  <div className="oa_blog_winner">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14.354"
                                      height="15.77"
                                      viewBox="0 0 14.354 15.77"
                                    >
                                      <path
                                        id="Icon_awesome-canadian-maple-leaf"
                                        data-name="Icon awesome-canadian-maple-leaf"
                                        d="M12.73,10.833c.077-.077,3.24-2.846,3.24-2.846l-.539-.231c-.308-.151-.228-.354-.154-.536.074-.234.619-2.073.619-2.073s-1.469.308-1.777.385c-.231.074-.308-.077-.385-.231s-.462-1-.462-1-1.62,1.845-1.7,1.919c-.308.231-.619,0-.542-.308,0-.308.85-3.992.85-3.992s-.927.536-1.235.69c-.231.154-.388.154-.542-.154C9.949,2.227,8.791,0,8.791,0S7.636,2.227,7.482,2.458c-.154.308-.308.308-.542.154-.308-.154-1.235-.69-1.235-.69s.85,3.684.85,3.992c.077.308-.231.539-.542.308-.077-.077-1.7-1.919-1.7-1.919s-.385.841-.462.995-.154.3-.385.231c-.311-.077-1.777-.385-1.777-.385s.542,1.839.619,2.073c.074.185.154.385-.154.536l-.539.234s3.16,2.769,3.24,2.846c.157.154.308.231.157.693S4.7,12.607,4.7,12.607s2.932-.619,3.243-.7c.268-.028.564.077.564.385s-.179,3.474-.179,3.474h.924s-.179-3.163-.179-3.474.293-.413.567-.385c.308.077,3.24.7,3.24.7s-.154-.619-.308-1.081,0-.539.154-.693Z"
                                        transform="translate(-1.617)"
                                        fill="#fff"
                                      ></path>
                                    </svg>
                                    <p className="oa_blog_winner_text">
                                      {cs.imageText}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="obie_modal_content">
                                <div className="modal_source_wrap">
                                  <div className="modal_source_info">
                                    <p>Source:</p>
                                  </div>
                                  <div className="modal_obie_awards_details">
                                    <p>{cs.source}</p>
                                  </div>
                                </div>
                                <div className="modal_source_wrap">
                                  <div className="modal_source_info">
                                    <p>Product Category:</p>
                                  </div>
                                  <div className="modal_obie_awards_details">
                                    <p>{cs.category}</p>
                                  </div>
                                </div>
                                <div className="modal_source_wrap">
                                  <div className="modal_source_info">
                                    <p>Creative Award:</p>
                                  </div>
                                  <div className="modal_obie_awards_details">
                                    <span className="modal_dots bronze_color">
                                      {" "}
                                    </span>
                                    <p>{cs.award}</p>
                                  </div>
                                </div>
                                <div className="modal_source_wrap">
                                  <div className="modal_source_info">
                                    <p>Country:</p>
                                  </div>
                                  <div className="modal_obie_awards_details">
                                    <p>{cs.country}</p>
                                  </div>
                                </div>
                              </div>

                              <div className="casestudy-modal-content main">
                                <div className="">
                                  {renderRichText(cs.content)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Modal>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default InsightsReports

export const insightsReports = graphql`
  query Insights($language: String) {
    allContentfulInsightsReports(filter: { node_locale: { eq: $language } }, sort: { fields: reportDate, order: DESC}) {
      nodes {
        id
        image {
          gatsbyImageData
        }
        title
        reportDate
        subtitle
      }
    }
  }
`